
import Vue from "vue";
import { tableColumnListAgentReports } from "./AgentReportsTable";
import { ITableWrapperColumns } from "@/types";
import {
  agentReportMapActions,
  agentReportMapGetters,
  agentReportMapState,
  agentReportMapMutations
} from "@/store/modules/agentreports";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { get } from "lodash";
interface IAgentReportsData {
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  agentReportId: string;
  reportType: ("experienceReports" | "commissionReports")[];
  searchValue: string;
  yearPicked: string;
  actualYear: number;
  loadingText: string;
  filters: any[];
}
export default Vue.extend({
  name: "ListAgentReportsTable",
  components: {
    NoResultsFound
  },
  props: {
    queryOverride: {
      type: Object,
      required: false,
      default: () => ({})
    },
    emitOnAction: {
      type: Boolean,
      default: false
    },
    additionalTableActions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data(): IAgentReportsData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [...(this.additionalTableActions as any)],
      tableColumns: tableColumnListAgentReports,
      loading: false,
      agentReportId: "",
      reportType: [],
      searchValue: "",
      yearPicked: "",
      actualYear: 0,
      loadingText: "",
      filters: [
        {
          field: "type",
          title: "Report type",
          options: [
            { label: "Experience Reports", value: "experienceReports" },
            { label: "Commission Reports", value: "commissionReports" }
          ]
        }
      ]
    };
  },
  computed: {
    ...agentReportMapGetters([
      "agentreports",
      "getTotalRecords",
      "getAgentReportLinks"
    ]),
    ...agentReportMapState(["makingApiRequest"]),
    windowSize(): string {
      if (window.outerWidth <= 800) {
        return "sm";
      } else {
        return "lg";
      }
    },
    filterClass(): string {
      return this.windowSize === "sm"
        ? "hide-search"
        : this.$getCurrentUser && this.$getCurrentUser.role !== "admin"
        ? "report-list-view-agent"
        : "report-list-view";
    }
  },
  methods: {
    ...agentReportMapMutations(["SET_STATES"]),
    doAgentReportSearch(searchValue?: string | null): void {
      //only admins are allowed to search by agent code. only start search if agent code entered is more than 3
      if (
        this.$getCurrentUser.role === "admin" &&
        searchValue &&
        searchValue.length < 3
      ) {
        return;
      }

      this.searchInputChange(searchValue);
    },
    searchInputChange(searchValue?: string | null): void {
      const query: any = { agentCode: searchValue, __limit: 50, __page: 1 };
      if (this.actualYear) {
        query["year"] = this.actualYear;
      }
      if (this.reportType.length) {
        query["type"] = this.reportType;
      }

      this.fetchPageData(query);
    },
    yearChanged(date: Date): void {
      const query: any = {
        agentCode: this.searchValue,
        __limit: 50,
        __page: 1
      };
      this.actualYear = date ? date.getFullYear() : 0;
      if (this.actualYear) {
        query["year"] = this.actualYear;
      }
      if (this.reportType.length) {
        query["type"] = this.reportType;
      }
      if (
        this.searchValue.length >= 3 ||
        this.$getCurrentUser.role === "agent" ||
        this.$getCurrentUser.role === "agencyAdmin" ||
        this.$getCurrentUser.role === "admin"
      ) {
        this.fetchPageData(query);
      }
    },
    clearSearchQuery(): void {
      this.searchValue = "";
      this.fetchPageData({});
    },
    ...agentReportMapActions([
      "getAgentReports",
      "exportAgentReportFile",
      "deleteAgentReport"
    ]),
    tableActionHandler(action: string): any {
      switch (action) {
        default:
          if (this.emitOnAction) {
            return this.$emit("tableAction", action);
          }
          break;
      }
    },
    async fetchPageData(queryObject: Record<string, any>): Promise<void> {
      try {
        this.$emit("setErrorMessage", "");
        this.loadingText = `Fetching agent reports. Please wait...`;
        this.$emit("scrollTop");
        if (this.$getCurrentUser.role !== "admin") {
          queryObject.agentCode = this.$getCurrentUser.agentCode;
          if (this.actualYear) {
            queryObject.year = this.actualYear;
          }
        } else {
          if (this.searchValue) {
            queryObject.agentCode = this.searchValue;
          }
          if (this.actualYear) {
            queryObject.year = this.actualYear;
          }
        }
        if (
          queryObject &&
          !queryObject.type__in &&
          (!queryObject.type || !queryObject.type.length)
        ) {
          queryObject.type = ["experienceReports", "commissionReports"];
          this.reportType = [];
        } else if (queryObject.type__in) {
          this.reportType = queryObject.type__in;
        }
        await this.getAgentReports({
          query: { ...queryObject, ...this.queryOverride }
        });
      } catch (error) {
        const errorMessage =
          error.message || `Error fetching agent reports. Try again later.`;
        this.$emit("setErrorMessage", errorMessage);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loadingText = "";
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      try {
        const { agentCode, Key: fileName } = dataReceived;
        await this.exportAgentReportFile({ agentCode, fileName });
      } catch (error) {
        const errorMessage = get(
          error,
          "message",
          "Error fetching file. Try again later"
        );
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(errorMessage);
      }
    },
    async handleResponseAction(payload: any): Promise<void> {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router
            .push(`/admin/agentreport/${data._id}/edit`)
            .catch(() => {});
          break;
        case "view":
          this.$router.push(`/admin/agentreport/${data._id}`).catch(() => {});
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneAgentReports(): Promise<void> {
      try {
        this.loadingText = "Deleting agent report. Please wait...";
        await this.deleteAgentReport(this.agentReportId);
        this.$modal.hide("agenciesReportsListModal");
        this.$notify.success("AgentReports Successfully Deleted");
        this.$router.replace("/admin/agentreport").catch(() => {});
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
      }
    },
    async deleteCallback(agentReportId: any): Promise<void> {
      try {
        this.agentReportId = agentReportId;
        this.$modal.show("agenciesReportsListModal");
      } catch (error) {
        if (error !== "cancel") {
          console.error(error);
        }
        this.$bugSnagClient.notify(error);
      }
    }
  },
  destroyed(): void {
    this.SET_STATES({ agentreports: [] });
  },
  created(): void {
    this.SET_STATES({ agentreports: [] });
  }
});
