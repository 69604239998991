import * as dateFns from "date-fns";

function formatDate(dateString: string) {
  if (!dateString) return "N/A";
  const potentialDate = dateFns.parseISO(dateString);
  if (dateFns.isValid(potentialDate))
    return dateFns.format(potentialDate, "M/d/yyyy");
  else return "N/A";
}
export const tableColumnListAgentReports = {
  Key: {
    formatDataKey: "Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  reportType: {
    formatDataKey: "Report type",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  Size: {
    formatDataKey: "Size",
    formatDataValue: (arg: number) => `${Math.round(arg / 1000)} KB`,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  },
  LastModified: {
    formatDataKey: "Last Modified",
    formatDataValue: (arg: string) => formatDate(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3
  }
};
